/** Generated from ETgaFigure.java. Do not modify this file! */


export type ETgaFigureEntry = 'TEST_GAP_STATES' | 'NUMBER_OF_TEST_GAPS' | 'EXECUTION_STATES' | 'TEST_GAP_CHURN';

export class ETgaFigure {
	static readonly TEST_GAP_STATES = new ETgaFigure(0, 'TEST_GAP_STATES', "Test Gap", "Overview of all test gap states", "{red} untested additions, {orange} untested changes, {green} tested changes, {gray} no change");
	static readonly NUMBER_OF_TEST_GAPS = new ETgaFigure(1, 'NUMBER_OF_TEST_GAPS', "# Test Gaps", "The number of test gaps", "{red} test gaps");
	static readonly EXECUTION_STATES = new ETgaFigure(2, 'EXECUTION_STATES', "Execution", "The parts of the system executed during the tests", "{green} executed, {gray} not executed");
	static readonly TEST_GAP_CHURN = new ETgaFigure(3, 'TEST_GAP_CHURN', "Churn", "The code churn", "{red} additions, {orange} changes");
	static readonly values = [
		ETgaFigure.TEST_GAP_STATES,
		ETgaFigure.NUMBER_OF_TEST_GAPS,
		ETgaFigure.EXECUTION_STATES,
		ETgaFigure.TEST_GAP_CHURN
	];



	private constructor (
		public readonly ordinal: number,
		public readonly name: ETgaFigureEntry,
		public readonly displayName: string,
		public readonly description: string,
		public readonly tooltipTemplate: string
	){
	}

	toString() {
		return this.name;
	}
}

